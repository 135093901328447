.pagination {
  margin: 20px 0 40px;
  padding: 40px 0;
  text-align: center;

  @media only screen and (max-width: $mobile) {
    margin: 0 0 20px;
  }
}

.pagination__list {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 24px;

  .next-link,
  .previous-link,
  .active-link,
  .count-number {
    display: inline-block;
    padding: 16px 24px;
    font-size: 16px;
    text-transform: uppercase;
    transition: all .35s;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .15);
    background: $white;
    border-bottom: none;

    &.disabled {
      pointer-events: none;
    }

    &.active-link {
      font-weight: bolder;
      background: hsl(346, 40%, 75%);
      color: white;
    }
  }

  .next-link,
  .previous-link {
    color: $dark;

    &:hover {
      color: $black;
    }
  }

  .disabled {
    color: $gray;
    cursor: not-allowed;
  }

  @media only screen and (max-width: $mobile) {
    flex-direction: column;

    .next-link,
    .previous-link,
    .disabled {
      margin: 10px 0;
    }
  }
}

.counter-box {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 24px;
}
