// *****************
// Global Variables
// *****************

// Spaces
$base-spacing-unit: 1rem;

// Border radius
$global-radius: 4px;

// Transition
$global-transition: all .35s;


// *****************
// Color Variables
// *****************
$brand-color: hsl(345, 40%, 35%);
$dark: #393e46;
$gray: #bbbbbb;
$light-gray: #e6eef0;
$white: #ffffff;
$black: #000000;

// Background Colors
$content-background-color: hsl(27, 20%, 95%);
$background-color: hsl(27, 20%, 90%);
$background-color-dark: #EAE3DE;

// Body Color
$text-color: $dark;

// Headings
$heading-font-color: $dark;

// Links
$link-color: $brand-color;
$link-color-hover: darken($dark, 15%);

// Button
$button-color: $white;
$button-background-color: $brand-color;

// Border
$border-color: $light-gray;


// *****************
// Typography
// *****************
$base-font-size: 1rem;
$base-font-style: normal;
$base-font-variant: normal;
$base-font-weight: normal;
$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI Web Regular", "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";
$base-line-height: 1.4;

// Headings
$heading-font-weight: 700;
$heading-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI Web Regular", "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";

$font-size-h1:  36px;
$font-size-h2:  28px;
$font-size-h3:  24px;
$font-size-h4:  20px;
$font-size-h5:  18px;
$font-size-h6:  16px;

$heading-line-height: 1.2;
