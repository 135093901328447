.home {
  h2 {
    font-size: 24px;
  }

  .section {
    position: relative;
    margin: 2rem 0;
    padding: 1rem;
    background-color: var(--section-bc);
    border-radius: 4px;
  
    > :first-child {
      margin-top: 0;
    }
  
    > :last-child {
      margin-bottom: 0;
    }
  
    > :last-child > img {
      margin-bottom: 1rem !important;
    }
  }

  .columns-2 {
    margin-top: 2rem;
    display: flex;
    flex-basis: 100%;
    align-items: start;
    justify-content: space-evenly;
    flex-wrap: wrap;
    gap: 2rem;

    > * {
      flex-grow: 1;
      flex-basis: 400px;
    }
  }

  .sello-colegial {
    position: absolute;
    bottom: 1rem;
    right: 2rem;
  }

  .contact {
    h2 { 
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 1100px) {
    .sello-colegial {
      position: static;
    }
  }
}
