.arhive-head {
  padding: 80px 0;
  margin-bottom: 40px;

  .archive-title {
    font-size: 3rem;
    line-height: 1.1;
    margin-top: 80px;

    text-align: center;
    font-weight: normal;
    
    span {
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  @media only screen and (max-width: $mobile) {
    margin-bottom: 40px;
  }
}