.topMenu {
  z-index: 2;
  position: sticky;
  top: 0;
  width: 100%;
  background-color: $background-color;
}

.contact-topMenu {
  color: white;
  text-align: center;
  background: $brand-color;
  padding: 0.2rem;
  font-size: 0.9rem;
  line-height: 1.75;
  font-weight: normal;

  .items {
    display: flex;
    flex-wrap: wrap;
    gap: 0 16px;
    align-items: center;
    justify-content: center;

    a {
      min-height: 25px;
      font: inherit;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      gap: 4px;
    }

    span {
      line-height: 1;
    }
  }

  @media only screen and (max-width: 650px) {
    .container {
      padding: 0 6px;

      .row {
        margin: 0 -6px;

        .col {
          padding: 0 6px;
        }
      }
    }

    .location {
      width: 100%;
    }
  }
}

.header {
  position: relative;
  height: 56px;

  .header__overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: rgba(17, 17, 17, 0.7);
    cursor: pointer;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;

    @media only screen and (max-width: $desktop) {
      &.is-visible {
        opacity: 1;
        visibility: visible;
      }
    }
  }

  @media screen and (max-width: $desktop) {
    height: 48px;
  }
}

.logo {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);

  .logo__link {
    display: flex;
    font-size: 30px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: $dark;
    text-decoration: none;

    &:hover {
      color: $black;
    }
  }

  .logo__image {
    height: 2rem;
  }
}

.main-nav {
  position: absolute;
  top: 50%;
  left: 48px;
  transform: translateY(-50%);

  .nav-icon__close {
    display: none;
    font-size: 24px;
    text-align: right;
    cursor: pointer;

    &:hover {
      color: $black;
    }
  }

  .nav__title {
    display: none;
  }

  .nav__section {
    position: relative;

    & > .nav__link {
      display: flex !important;
      align-items: center;
      gap: 8px;

      .arrow {
        flex-shrink: 0;
        transition: all 0.3s;
        transform: rotate(180deg);
      }
    }

    & > .nav__list {
      display: none;
    }

    @media only screen and (max-width: $desktop) {
      &[data-open="false"] {
        & > .nav__list {
          display: none;
        }
      }

      &[data-open="true"] {
        border-left: 2px solid $brand-color;
        margin-left: -2px;

        & > .nav__link {
          color: $brand-color !important;

          .arrow {
            transform: rotate(0deg);
          }
        }

        & > .nav__list {
          display: block;
        }
      }
    }

    @media only screen and (min-width: $desktop) {
      &:hover {
        & > .nav__link {
          color: $brand-color !important;
        }

        & > .nav__list {
          display: block;
          position: absolute;
          top: 40px;
          background: #ebe5e0;
          padding: 10px 5px;
          display: flex;
          flex-direction: column;
          min-width: 200px;
          width: max-content;
          box-shadow: 5px 10px 20px rgba(0, 0, 0, 0.5);
        }
      }
    }
  }

  .nav__list {
    white-space: nowrap;

    .nav__item {
      display: inline-block;

      .nav__link {
        display: inline-block;
        text-align: left;
        padding: 10px 30px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.5px;
        text-transform: uppercase;
        color: $dark;

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            color: $brand-color !important;
          }
        }
      }
    }
  }

  @media only screen and (max-width: $desktop) {
    position: fixed;
    top: 0;
    left: inherit;
    right: -300px;
    bottom: 0;
    transform: none;
    z-index: 100;
    width: 300px;
    height: 100vh;
    padding: 1rem;
    transition: right 0.5s cubic-bezier(0.7, 0.4, 0, 1);
    background: $white;

    &.is-open {
      right: 0;

      .nav__list .nav__item .nav__link {
        display: block;
        width: 100%;
        padding: 10px 10px 10px 20px;
      }
    }

    .nav-icon__close {
      display: block;
    }

    .nav__title {
      display: block;
      padding-left: 30px;
      padding-bottom: 7px;
      margin-bottom: 10px;
      color: $gray;
      border-bottom: 1px solid $light-gray;
    }

    .nav__list {
      .nav__item {
        display: block;
      }
    }
  }
}

.nav-buttons {
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  font-size: 22px;
  display: flex;

  .nav__icon {
    color: $dark;
    cursor: pointer;
    text-align: center;

    &:hover {
      color: $black;
    }
  }

  .nav__icon-menu {
    display: none;
    margin-left: 10px;

    &:hover {
      color: $black;
    }

    @media only screen and (max-width: $desktop) {
      display: inline-block;
    }
  }

  .nav__icon-search {
    padding: 2px;
  }

  @media only screen and (max-width: $desktop) {
    font-size: 2rem;

    .nav__icon {
      min-width: 2rem;
      min-height: 2rem;
    }
  }
}
