.subscribe {
  width: 430px;
  max-width: 100%;
  margin: 0 auto 80px;
  text-align: center;

  .subscribe__title {
    margin-bottom: 10px;
    font-size: 37px;
    line-height: 57px;
  }

  .subscribe__subtitle {
    margin-bottom: 30px;
    font-size: 17px;
    line-height: 27px;
  }

  .subscribe-form {

    .subscribe-group {
      margin-bottom: 30px;
    }

    .subscribe__email {
      width: 100%;
      padding: 17px;
      font-size: 16px;
      text-align: center;
      outline: none;
      border: none;
      border-bottom: 3px solid $light-gray;

      &::placeholder {
        font-size: 18px;
        color: $light-gray;
      }
    }

    .subscribe__button {
      width: 127px;
      height: 57px;
      font-size: 14px;
      font-weight: 700;
      letter-spacing: 0.5px;
      border-radius: 30px;
      border: none;
      background: $light-gray;
      cursor: pointer;
      transition: all .35s;

      &:hover {
        color: $white;
        background: $dark;
      }
    }
  }

  @media only screen and (max-width: $mobile) {
    margin: 0 auto 40px;
    
    .subscribe__subtitle {
      font-size: 16px;
      line-height: 26px;
    }
  }
}