.equipo__page {
  .member {
    display: grid;
    gap: 16px;
    grid-template-rows: auto 1fr;
    grid-template-areas:
      "img name"
      "img content";

    @media only screen and (min-width: $tablet) {
      margin-top: 3em;
      gap: 32px;
    }

    .member__img {
      grid-area: img;

      height: 450px;
      object-fit: cover;
      align-self: start;

      @media only screen and (max-width: $tablet) {
        width: 100%;
        height: 350px;
      }

      @media only screen and (max-width: $mobile) {
        width: 100%;
        height: 275px;
      }
    }

    .member__name {
      grid-area: name;

      @media only screen and (min-width: $tablet) {
        margin: 0;
      }
    }

    .member__content {
      grid-area: content;
    }

    .member__navigation {
      float: right;
    }

    @media only screen and (max-width: $tablet) {
      grid-template-areas:
        "name"
        "img"
        "content";
    }
  }
}
