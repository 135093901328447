.footer {
  position: relative;
  padding: 1rem 0 5rem 0;
  border-top: 1px solid $light-gray;
  background: $background-color;

  .columns {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap-reverse;
    gap: 3rem;
  }

  .left-column, .right-column {
    flex: 1;
    display: flex;
  }

  .left-column {
    justify-content: end;
  }

  .right-column {
    justify-content: start;
  }

  .copyright {
    display: flex;
    flex-direction: column;
    align-items: center;

    a {
      color: $dark;
      &:hover {
        color: $brand-color;
      }
    }
  }

  .contacts {
    display: flex;
    flex-direction: column;
    align-items: start;

    a {
      display: inline-flex;
      flex-wrap: nowrap;
    }

    img {
      margin-right: 8px;
      filter: brightness(0.25);
    }
  }

  .networks {
    text-align: center;
  }
}
