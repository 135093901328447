.page-title-box {
  padding: 4rem 0 0 0;
  border-top: 1px solid $light-gray;

  .page-title {
    font-size: 3rem;
    margin: 0;
    line-height: 1.1;
  }

  @media only screen and (max-width: $mobile) {
    padding: 2rem 0 0 0;

    .page-title {
      font-size: 2.5rem;
    }
  }
}

.page__head {
  
  .page-image {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: $light-gray;
    &:after {
      content: "";
      display: table;
      width: 100%;
      min-height: 300px;
      padding-top: 50%;

      @media only screen and (max-width: $mobile) {
        min-height: 180px;
      }
    }
  }
}

.page {
  margin: 2rem auto 4rem auto;
  
  @media only screen and (max-width: $tablet) {
    margin: 1rem auto 2rem auto;
  }
}

.page, .post {
  p:not(.carousel-cell) > img {
    margin: 30px auto;
    display: block;

    &.left {
      float: left;
    }

    &.right {
      float: right;
    }
  }
}

.page-box {
  text-align: center;

  .back-arrow {
    a {
      display: inline-block;
      margin-left: 10px;
      color: $dark;
      font-weight: 700;
      &:hover {
        color: $black;
      }
    }
  }
}