.me {
  grid-area: me;
  align-self: end;
  margin: 2rem 0 1rem 0;
  display: inline-grid;
  column-gap: 2rem;
  grid-template-columns: 50% 1fr auto;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    "img title title"
    "img text text"
    "img networks about-me"
    "img pricing pricing";

  > img {
    grid-area: img;
    object-fit: cover;
    align-self: center;
    border-radius: 1rem;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
  }

  > .title {
    display: flex;
    flex-wrap: wrap;
    font-size: 2rem;
    grid-area: title;
    align-self: end;
    margin-top: 1rem;
    margin-bottom: 0;
  }

  > .title > .subtitle {
    font-weight: normal;
  }

  > .text {
    grid-area: text;
    align-self: center;
    margin: 1rem 0;
  }

  > .networks {
    grid-area: networks;
    align-self: start;
    justify-self: start;
    margin-top: 0.5rem;

    a > img,
    a > svg {
      width: 32px;
      padding: 2px 4px;
    }
  }

  > .about-me {
    grid-area: about-me;
    align-self: start;
    justify-self: end;
    margin-top: 0.5rem;
  }
}

@media only screen and (max-width: 1200px) {
  .me {
    margin: $base-spacing-unit 0;
  }

  .tooltip .tooltipcontent.bottom {
    top: 120% !important;
  }
}

@media only screen and (max-width: 1100px) {
  .me {
    grid-template-columns: 40% 1fr auto;
    grid-template-rows: auto auto auto auto;
  }
}

@media only screen and (max-width: 920px) {
  .me {
    grid-template-columns: 40% 1fr auto;
    grid-template-rows: auto auto auto auto;
    grid-template-areas:
      "img title title"
      "img text text"
      "img networks about-me"
      "pricing pricing pricing";

      > .pricing {
        margin-top: 2rem;
      }
  }
}

@media only screen and (max-width: 650px) {
  .me {
    margin: 0;
    grid-template-areas:
      "img"
      "title"
      "networks"
      "text"
      "about-me"
      "pricing";
    grid-template-rows: auto;
    grid-template-columns: auto;
    justify-items: center;

    align-items: center;

    > img,
    .networks {
      justify-self: center;
    }

    > img {
      width: 100vw;
      max-width: 100vw;
      height: 210px;
      max-height: 210px;
      margin: 0 #{-1 * $base-spacing-unit};
      margin-bottom: 1rem;
      border-radius: 0;
    }

    > .title {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 1.5rem;
      align-self: end;
    }

    > .text {
      margin-bottom: 0;
    }
  }
}
