.post-title-box {
  padding: 80px 0;
  border-top: 1px solid $light-gray;

  .post-title {
    margin-top: 10px;
    margin-bottom: 10px;
    max-width: 100%;
    font-size: 57px;
    line-height: 1.1;
  }

  .post__date {
    font-weight: 700;
  }

  @media only screen and (max-width: $mobile) {
    padding: 40px 0;

    .post-title {
      margin: 10px 0;
      font-size: 34px;
      line-height: 36px;
    }
  }
}

.post__info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 16px;

  .post__author {
    margin-right: 10px;
    font-weight: 700;
  }

  .post__tags {
    .post__tags-tag {
      font-weight: 700;
      line-height: 2em;
      white-space: nowrap;
      border: 1px solid;
      padding: 4px 8px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.6);
      text-transform: uppercase;

      @media only screen and (max-width: $mobile) {
        font-size: 14px;
      }
    }
  }
}

.post-image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: $light-gray;
  &:after {
    content: "";
    display: table;
    width: 100%;
    min-height: 300px;
    padding-top: 35vh;

    @media only screen and (max-width: $mobile) {
      min-height: 180px;
    }
  }
}

.post {
  margin: 80px auto;

  &.post-no-image {
    margin: 0 auto 80px;
  }

  @media only screen and (max-width: $mobile) {
    margin: 40px auto;

    &.post-no-image {
      margin: 0 auto 40px;
    }
  }

  img {
    margin: 30px 0;
  }
}

.post__content {
  margin-bottom: 40px;
}

.post-content__subtitle {
  margin-top: 0;
}

.post__share {
  display: none !important;
  display: flex;
  align-items: center;

  .share__title {
    margin-right: 10px;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.5px;
  }

  .share__list {
      a {
        margin: 0 4px;
        img { margin: 0; }
    }
  }
}

.post__author-bottom {
  margin-top: 16px;

  &.card {
    flex-direction: column;
  }

  .img {
    background-size: cover;
    background-image: var(--img);
    background-position: center center;
    width: 150px;
  }

  .written-by {
    color: gray;
    font-size: 14px;
  }

  .author-name {
    color: var(--primary-color);
    font-size: 24px;
    font-weight: 700;
  }

  .author-description {
    padding-top: 8px;
  }

  .author-navigation {
    margin-top: 16px;
    float: right;
  }
}

.post__navigation {
  display: flex;
  align-items: baseline;
  margin-bottom: 80px;

  .prev,
  .next {
    flex: 1 0 40%;

    background: white;
    border-radius: 4px;

    &:last-child {
      padding-right: 0;
    }

    .post__nav__image {
      display: block;
      margin-bottom: 40px;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      background-color: $light-gray;
      transition: 0.35s ease-in-out;
      &:hover {
        opacity: 0.9;
      }
      &:after {
        content: "";
        display: table;
        width: 100%;
        padding-bottom: 25vh;
      }
    }

    .post__nav__title {
      margin-bottom: 20px;
      font-size: 27px;
      line-height: 37px;
      text-transform: uppercase;
      letter-spacing: 2px;
      text-align: center;

      a {
        padding: 0 10px;
      }
    }
  }

  .prev {
    margin-right: 8px;
  }

  .next {
    margin-left: 8px;
  }

  .prev__excerpt,
  .next__excerpt {
    width: 100%;
    margin: 0 auto;
    text-align: center;
    padding: 16px;
  }

  @media only screen and (max-width: $tablet) {
    flex-wrap: wrap;

    .prev,
    .next {
      flex: 1 0 100%;
      margin: 0;
    }

    .next {
      margin-top: 40px;
    }
  }

  @media only screen and (max-width: $mobile) {
    margin-bottom: 40px;
    .prev,
    .next {
      .post__nav__image {
        margin-bottom: 20px;
      }
    }
  }
}
