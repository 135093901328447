.pricing {
  grid-area: pricing;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .price-cards {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;

    @media only screen and (max-width: 650px) {
      gap: 1rem;
    }

    .price-card {
      flex: 1;
      min-width: fit-content;
      max-width: 300px;

      display: grid;
      grid-template-areas:
        "img title"
        "img price"
        "img more";
      grid-auto-columns: auto 1fr;
      align-items: center;
      justify-items: center;

      position: relative;
      color: var(--primary-color);
      background-color: rgba(255, 255, 255, 0.6);
      padding: 1rem;
      border-radius: 8px;
      font-size: 1.1rem;
      text-align: center;

      & > .img {
        grid-area: img;
        margin-right: 8px;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 72px;
        width: 72px;
        padding: 12px;
        border-radius: 100%;
        background-color: var(--primary-color-light);

        > img {
          object-fit: contain;
          filter: brightness(10);
        }
      }

      & > .title {
        grid-area: title;
        font-weight: 600; // 500 doesn't work on android/ios
      }

      & > .price {
        grid-area: price;
        white-space: nowrap;
      }

      > .more {
        grid-area: more;
        font-size: 0.8rem;
        margin-top: 0.5rem;
      }
    }
  }

  .cite {
    font-size: 12px;
    text-align: center;
    padding: 0 8px;
  }
}
