.search {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
  transform: scale(0.95);
  opacity: 0;
  background: $background-color-dark;
  transition: all 250ms ease;
  &.is-visible  {
    transform: scale(1);
    z-index: 100;
    opacity: 1;
    transition: all 250ms ease;
  }
}

.search__box {
  width: 50%;
  margin: 0 auto;
  padding-top: 100px;
  
  @media only screen and (max-width: $desktop) {
    width: 70%;
    padding-top: 70px;
  }
  
  @media only screen and (max-width: $mobile) {
    width: 100%;
    padding-top: 30px;
  }
}

.search__group {
  margin-bottom: 50px;

  .search__close {
    margin-bottom: 30px;
    font-size: 27px;
    text-align: right;
    cursor: pointer;
    &:hover {
      color: $black;
    }
  }

  .search__text {
    width: 100%;
    padding: 20px 20px 20px 30px;
    letter-spacing: 0.5px;
    border-radius: 30px;
    border: 1px solid #f8f8f8;
    color: $dark;
    background: #f8f8f8;
    &::placeholder {
      letter-spacing: 0.5px;
      color: rgba($dark, 0.7);
    }
  }
}

.search-results-list {
  height: 65vh;
  margin: 0;
  padding: 0 20px;
  list-style: decimal;
  overflow: auto;
  white-space: normal;

  .search-results__item {
    margin-bottom: 10px;
    padding-left: 10px;
    padding-bottom: 10px;
    font-weight: 700;
    border-bottom: 1px solid #f5efef;
    &:first-child {
      padding-top: 20px;
      border-top: 1px solid #f5efef;
    }
  }

  .search-results__link {
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.5px;
    color: $dark;
    &:hover {
      color: $black;
    }
  }

  .search-results__date {
    font-size: 12px;
    color: $gray;
  }

  .no-results {
    list-style: none;
    padding: 20px 0;
    font-size: 18px;
    font-weight: 700;
    letter-spacing: 0.5px;
    border-top: 1px solid #f5efef;
    border-bottom: 1px solid #f5efef;
  }
}