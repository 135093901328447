.networks {
  margin-bottom: 2rem;

  .tooltip .tooltipcontent.bottom {
    top: 100%;
  }

  img {
    width: 40px;
    height: 40px;
    padding: 6px;
  }
}
