.article {
  margin-top: 20px;
  margin-bottom: 20px;

  .article__wrapper {
    display: flex;
    align-items: center;
  }

  .article__image {
    width: 50%;
    display: block;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-color: $light-gray;
    &:hover {
      opacity: 0.9;
    }

    &:after {
      content: "";
      display: table;
      width: 100%;
      padding-bottom: 70%;
    }
  }

  .article__content {
    width: 60%;
    padding: 50px;
    margin-left: -10%;
    z-index: 1;
    border-radius: 3px;
    background: rgba(255, 255, 255, 0.98);
    box-shadow: 0 1px 4px rgba(0,0,0,0.15);

    .article-tags__box {
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-end;
      flex-direction: row;
      color: var(--primary-color);
      gap: 8px;
      margin-bottom: 16px;
      font-size: 11px;
    }

    .article__tag {
      font-weight: 700;
      line-height: 2em;
      white-space: nowrap;
      border: 1px solid;
      padding: 2px 4px;
      border-radius: 4px;
      background-color: rgba(255, 255, 255, 0.6);
      text-transform: uppercase;
    }

    .article__title {
      margin-top: 0;
      margin-bottom: 1rem;
    }

    .article__excerpt {
      margin-bottom: 20px;
      font-size: 16px;
      line-height: 26px;
    }

    .article__meta {
      font-size: 12px;
      line-height: 18px;
    }

    .article__date {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;
    }

    .article__footer {
      display: flex;
      align-items: center;

      .read-more {
        margin-left: auto;
      }
    }
  }

  .article-without-image {
    width: 100%;
    margin-left: auto;
  }

  @media only screen and (max-width: $tablet) {
    .article__wrapper {
      flex-direction: column;
    }

    .article__image {
      width: 100%;
    }

    .article__content {
      width: 90%;
      padding: 20px;
      margin: -10% 0 0 0;
    }

    .article-without-image {
      width: 90%;
      margin: 0;
    }
  }

  @media only screen and (max-width: $mobile) {
    .article__content {

      .article__title {
        font-size: 27px;
      }
    }
  }
}