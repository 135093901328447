.opinions {
  position: relative;
  grid-area: opinions;
  margin: 0;
  padding: 1rem;
  background-color: var(--section-bc);
  border-radius: 4px;
}

.opinions .title {
  margin-top: 0;
}

.opinions iframe {
  height: 380px;
  max-height: #{"min(50vh, 380px)"};

  width: calc(100% + 16px);
  margin-left: -16px;
  margin-right: -8px;
  margin-top: -4px;
}

.opinions .scroll {
  overflow-y: scroll;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 0.5rem;
  padding-right: 0;
  max-height: #{'min(50vh, 350px)'};

  & > :first-child {
    margin-top: 0;
  }

  & > :last-child {
    margin-bottom: 0;
  }

  .stars {
    font-size: 0;
  }

  p {
    margin-top: 0;
    margin-right: 0;
  
    font-style: italic;
    font-size: 15px;
    line-height: 1.15em;
    border-left: 2px solid var(--primary-color-light);
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.opinions .post-shadow {
  background: linear-gradient(0deg, rgba(0,0,0,0.20), transparent);
    width: calc(100% - 32px);
    height: 20px;
    margin-top: -20px;
    z-index: 999;
    position: absolute;
}

.opinions .links {
  height: auto;
  background-color: rgb(246, 244, 242);
  display: flex;
  gap: 16px;
  padding: 8px;
  margin-top: 8px;
  flex-direction: row;
  align-items: center;
  border-radius: 8px;

  .logos {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    column-gap: 16px;

    .rating {
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;
      .count {
        font-size: 14px;
          color: #858585;
      }
    }
  }

  .action {
    background: #3480dc;
    color: white;
    border-radius: 50px;
    padding: 4px 12px;
    font-weight: 500;
  }
}
